<template>
  <div>
    <div class="columns">
      <div class="column is-full-height is-hidden-mobile is-half branding-half">
        <article class="has-text-centered">
          <a href="/">
            <img
              style="max-width:40%; height:auto; display:block;margin:0 auto;"
              :src="require('@/assets/brand/HummingbirdWhite.png')"
            />
          </a>
          <h3 class="is-size-5 has-large-margin-top">{{ logoCopy }}</h3>
        </article>
      </div>
      <div class="column is-full-height is-half form-half">
        <article>
          <full-auth :page="page" @updatePage="onUpdatePage"></full-auth>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import FullAuth from '@/components/Auth/FullAuth.vue'

const defaultLogoCopy = 'Hey there!'
const pageLogoCopy = {
  signUp: 'Welcome to Floom!',
  signIn: 'Welcome back!',
  forgotPassword: 'This will just take a second!',
  confirmSignUp: 'This will just take a second!',
  signedOut: 'Hi from Floom!',
}

export default {
  name: 'Authenticator',
  components: {
    FullAuth,
  },
  data() {
    return {
      page: this.$route.params.slug,
      logoCopy: defaultLogoCopy,
    }
  },
  methods: {
    onUpdatePage(page) {
      this.logoCopy = pageLogoCopy[page] || defaultLogoCopy
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../style/helpers.scss';

article {
  position: relative;
  top: 50%;
  text-align: center;
  transform: translate(0, -50%);
  padding: 1rem;
  @include breakpoint(mobile) {
    top: 0;
    transform: translate(0, 10%);
  }
}
.branding-half {
  color: white;
  height: 102vh;
  background-image: linear-gradient(109.6deg, rgba(218, 185, 252, 1) 11.2%, rgba(125, 89, 252, 1) 91.1%);
}
</style>
